<template>
  <div>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
      >

        <!-- Organization -->
        <b-card
          title="Organization"
          class="mb-2"
        >
          <!-- Organization Id -->
          <validation-provider
            #default="validationContext"
            name="Organization Id"
            rules="required"
          >
            <b-form-group
              label="Organization ID*"
              label-for="organizationId"
            >
              <b-form-input
                id="organizationId"
                v-model="organizationId"
                plaintext
                placeholder="organization ID"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Organization name -->
          <validation-provider
            #default="validationContext"
            name="Organization name"
            rules="required"
          >
            <b-form-group
              label="Organization name*"
              label-for="organizationName"
            >
              <b-form-input
                id="organizationName"
                v-model="form.organizationName"
                placeholder="Organization Name"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-card>

        <!-- System -->
        <b-card
          title="System"
          class="mb-2"
        >
          <validation-provider
            #default="validationContext"
            name="timezone"
          >
            <b-form-group
              label="Timezone"
              label-for="timezone"
            >
              <v-select
                v-model="form.timezone"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="timezones"
                :reduce="timezone => timezone.offset"
                label="name"
                :clearable="false"
                class="per-page-selector time-dropdown"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
            <div>
              <label>Version number</label>
              <div style="padding: 0.438rem 0;">
                {{ versionNumber }}
              </div>
            </div>
          </validation-provider>

        </b-card>

        <!-- Authentication Methods -->
        <b-card
          title="Authentication Methods"
          class="mb-2"
        >
          <b-row>
            <b-col cols="4">
              <b-form-group
                label="Sign in with Email"
                label-for="isEmailAuthEnabled"
              >
                <b-form-checkbox
                  v-model="form.isEmailAuthEnabled"
                  :checked="form.isEmailAuthEnabled"
                  name="check-button"
                  switch
                  inline
                />

              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Sign in with Google"
                label-for="isGoogleAuthEnabled"
              >
                <b-form-checkbox
                  v-model="form.isGoogleAuthEnabled"
                  :checked="form.isGoogleAuthEnabled"
                  name="check-button"
                  switch
                  inline
                />

              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Sign in with Facebook"
                label-for="isFacebookAuthEnabled"
              >
                <b-form-checkbox
                  v-model="form.isFacebookAuthEnabled"
                  :checked="form.isFacebookAuthEnabled"
                  name="check-button"
                  switch
                  inline
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <!-- Reminders -->
        <b-card
          title="Reminders"
          class="mb-2"
        >
          <b-row>
            <b-col>
              <b-form-group
                label="Send weekly reminders via email"
                label-for="sendWeeklyRemindersViaEmail?"
              >
                <b-form-checkbox
                  v-model="form.sendWeeklyRemindersViaEmail"
                  :checked="form.sendWeeklyRemindersViaEmail"
                  name="check-button"
                  switch
                  inline
                />

              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Send daily reminders for late tasks via SMS"
                label-for="sendDailyRemindersForLateTasksViaSMS"
              >
                <b-form-checkbox
                  v-model="form.sendDailyRemindersForLateTasksViaSMS"
                  :checked="form.sendDailyRemindersForLateTasksViaSMS"
                  name="check-button"
                  switch
                  inline
                />

              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="mr-2"
            @click="form = { ...organizationSettings,
                             __typename: undefined}"
          >
            Cancel
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Submit
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard, BFormInput, BFormGroup, BButton, BForm, BFormInvalidFeedback, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import firebase from 'firebase'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { timezones } from '@/utils/timezones'

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BButton,
    BForm,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: this.getDefaultForm(),
      versionNumber: process.env.VUE_APP_BUILD_NUMBER,
    }
  },
  computed: {
    ...mapState('setting', ['organizationSettings']),
    ...mapState('user', ['currentUser']),
    organizationId() {
      return firebase.auth().currentUser.tenantId
    },
    timezones() {
      return timezones
    },
  },
  watch: {
    organizationSettings(value) {
      if (value) {
        this.form = {
          ...value,
          __typename: undefined,
        }
      }
    },
  },
  mounted() {
    this.fetchSettingsIfNotLoaded()
    if (this.organizationSettings) {
      this.form = {
        ...this.organizationSettings,
        __typename: undefined,
      }
    }
  },
  methods: {
    ...mapActions('setting', ['fetchSettingsIfNotLoaded']),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    getDefaultForm() {
      return {
        organizationName: '',
        timezone: '',
        isEmailAuthEnabled: '',
        isGoogleAuthEnabled: '',
        isFacebookAuthEnabled: '',
        sendWeeklyRemindersViaEmail: '',
        sendDailyRemindersForLateTasksViaSMS: '',
      }
    },
    onSubmit() {
      this.$store.dispatch('setting/updateSettings', this.form).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            icon: 'CoffeeIcon',
            variant: 'success',
            text:
              'You have successfully updated the organization settings.',
          },
        })
      })
    },
  },

}
</script>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select';
.time-dropdown{
  width: auto;
}
</style>
